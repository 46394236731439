import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Container, Row, Col, Button, Modal, Alert, Image, Table } from 'react-bootstrap';
import PaginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Spinner from 'react-bootstrap/Spinner';
import Editable from 'react-bootstrap-editable';
import { BsFileEarmarkPersonFill } from "react-icons/bs";
import Dayjs from 'dayjs';

const SubmittedTable = ( {submitted, loading, modalInfo, setModalInfo, rowEvents, show, handleClose, rejectApplication, approveApplication, 
    showSuccessAlert, showRejectAlert, showModalBody, showOkayButton, showModalButtons, updateSubmittedApplication, 
    SetBusinessName, SetDescription, SetOwnerName, businessType, SetBusinessType, SetPhone, SetEmail, SetWebsite, SetNaics, SetAddressLine1, SetAddressLine2,
    SetCity, SetProvince, SetPostalcode, SetNumberOfEmployees, setIndigenousOwnership, ownerName, email, businessName, 
    description, phone, website, naics, addressLine1, addressLine2, city, province, postalCode, numberOfEmployees, indigenousOwnership,
    awsUrl, setIsDirty, showUpdateSuccessAlert, showUpdateFailAlert, indigenousHeritage, setIndigenousHeritage } ) => {

    const { SearchBar } = Search;

    const handleAlertClose = () => window.location.reload();

    const dateSubmittedFormat = (cell, row, rowIndex) => {
        const dates = Dayjs(cell).format('YYYY-MM-DD')
        return dates;
    };

    //Define columns
    const columns = [
        { dataField: "", text: "", csvExport: false,
        formatter: () => {return <Button variant="success" type="button">View</Button>},
        headerStyle: () => {return { width: "5%" };} },
        { dataField: "BusinessName", text: "Business Name", headerStyle: () => {return { width: "27%" };} },
        { dataField: "Email", text: "Business Email", headerStyle: () => {return { width: "27%" };}  },
        { dataField: "Province", text: "Location",
        headerStyle: () => {return { width: "20%" };}  },
        { dataField: "Created", text: "Date Submitted", 
        formatter: dateSubmittedFormat,
        headerStyle: () => {return { width: "20%" };}  },
    ];

    const AttachmentButton = () => {
        return (
           <Button variant="primary" type="button" href={awsUrl} target="_blank"><BsFileEarmarkPersonFill/> View</Button> 
        )
    };

    const UpdateSuccessAlert = () => {
        return(
            <Alert variant="success" style={{ display: showUpdateSuccessAlert ? "block" : "none" }}>
                Application has been successfully updated for {modalInfo.BusinessName}
            </Alert>
        )
    };
    const UpdateFailAlert = () => {
        return(
            <Alert variant="danger" style={{ display: showUpdateFailAlert ? "block" : "none" }}>
                An error has occurred while attempting to update the application for {modalInfo.BusinessName}
            </Alert>
        )
    };

    //Modal
    const ModalContent = () => {

        return (
            
            <Modal 
            show={show}
            animation={false}
            scrollable={true}
            id="submittedModal" 
            onHide={handleClose}
            size="lg" 
            centered
        >
            <UpdateSuccessAlert />
            <UpdateFailAlert />
            
            <Modal.Header closeButton>
                <Modal.Title> {modalInfo.BusinessName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Alert variant="light" style={{ display: showSuccessAlert ? "block" : "none" }}>
                    <Alert.Heading>Successfully approved!</Alert.Heading>
                    <p>The application for {modalInfo.BusinessName} has been approved and will be visible in the indigenous businesses table.</p>
                    <Row className="justify-content-center">
                    <Image src='https://major-projects-directory.s3.ca-central-1.amazonaws.com/Images/Approved.png'
                            style={{height: 250, width: 300, alignContent: 'center'}}></Image>
                    </Row>
            </Alert>
            <Alert variant="light" style={{ display: showRejectAlert ? "block" : "none" }}>
                    <Alert.Heading>Application rejected</Alert.Heading>
                    <p>The application for {modalInfo.BusinessName} has been rejected and will no longer be visible in the submitted applications table</p>
                    <Row className="justify-content-center">
                    <Image src='https://major-projects-directory.s3.ca-central-1.amazonaws.com/Images/Cancelled.png'
                            style={{height: 250, width: 300, alignContent: 'center'}}></Image>
                    </Row>
            </Alert>
            <div style={{ display: showRejectAlert ? "block" : "none" }}>
                
            </div>
            <Container style={{ display: showModalBody ? "block" : "none" }}>

            <Table striped>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td><strong>NAICS: </strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={naics.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetNaics(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><strong>Owner name:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={ownerName.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true)}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetOwnerName(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><strong>Business type:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={businessType.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true)}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetBusinessType(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td><strong>Address:</strong></td>
                        <td>
                            <span style={{display: 'inline-block'}}>
                            <Editable 
                            editText="Edit"
                            initialValue={addressLine1.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetAddressLine1(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                            </span>
                            ,
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={addressLine2.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetAddressLine2(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                            </span>
                            
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={city.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetCity(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                            </span>
                            ,
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={province.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetProvince(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                            </span>
                            ,
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={postalCode.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetPostalcode(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><strong>Email Address:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={email.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetEmail(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td><strong>Phone:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={phone.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetPhone(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td><strong>Website:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={website.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetWebsite(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td><strong>Number of employees:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={numberOfEmployees.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetNumberOfEmployees(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td><strong>Indigenous heritage:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={indigenousHeritage.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {setIndigenousHeritage(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td><strong>% Indigenous ownership:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={indigenousOwnership.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {setIndigenousOwnership(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td><strong>Description:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={description.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetDescription(value) && updateSubmittedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td><strong>Attachment:</strong></td>
                        <td>
                            {awsUrl ? <AttachmentButton /> : <p>No attachment on record</p>}
                        </td>
                    </tr>
                </tbody>
            </Table>
            </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row style={{ display: showModalButtons ? "visible" : "none" }}>
                        <Col xs={6} md={10}>
                            <Button variant="danger" onClick={rejectApplication}>
                                Reject
                            </Button>
                        </Col>
                        <Col xs={6} md={2}>
                            <Button variant="success" onClick={approveApplication}>
                                Approve
                            </Button> 
                        </Col>  
                    </Row>
                    <Row>
                        <Col style={{ display: showOkayButton ? "block" : "none" }}>
                            <Button variant="primary" onClick={handleAlertClose}>
                                Okay
                            </Button> 
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
        )
    }

    //CSV Export button
    const MyExportCSV = (props) => {
        const handleClick = () => {
          props.onExport();
        };
        return (
            <Button variant="success" className='export-button pull-right' onClick={ handleClick }>Export</Button>
        );
    };

    return (
        <>
        {show ? <ModalContent /> : null}

        <ToolkitProvider
        
            keyField={"Id"}
            data={ submitted }
            columns={ columns }
            search
            exportCSV={{fileName: "Submitted.csv"}}
        >  
            {
                props => (
                    <div>

                        <Container className="p-0">
                            <Row>
                                <Col xs={12} md={6}>
                                    <SearchBar { ...props.searchProps } />
                                </Col>
                                <Col xs={0} md={5}></Col>
                                <Col xs={12} md={1}>
                                    <MyExportCSV { ...props.csvProps } />
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        {loading ? ( <BootstrapTable
                            { ...props.baseProps }
                            pagination={PaginationFactory()}
                            rowEvents={rowEvents}
                            wrapperClasses="table-responsive"
                        />) : (
                            <Container className="d-flex align-items-center justify-content-center">
                                <Spinner animation="border" variant="success" />
                            </Container>
                        )}
                    </div>
                )
                
            }
        </ToolkitProvider>
        </>
    )
}

export { SubmittedTable }
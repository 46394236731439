import React, { useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import {Button, InputGroup, Container, Row, Col, Image, Card} from 'react-bootstrap';
import { BsFillLockFill } from "react-icons/bs";

const ResetFormLoggedOut = ({handleSubmit, confirmPassword, setConfirmPassword, password, setPassword, pwRef, errRef, errMsg, setErrMsg, success}) => {

    const navigate = useNavigate();

    useEffect(() => {
        setErrMsg('');
    }, [confirmPassword, password])

    const returnHome = () => {
        navigate('/login')
    };

    const AlertSuccess = () => {
        return(
            <>
                <br/>
                <Image 
                    src="https://major-projects-directory.s3.ca-central-1.amazonaws.com/Images/Password+Reset.png"
                    className="pb-2 pt-2" 
                    style={{width: 200, alignContent: 'center'}}
                    >
                </Image>
                <p>{success.message}</p>
            </>
        )
    };

    const ResetButton = () => {
        return(
            <Button variant="primary" type="submit">Reset</Button>
        )
    };

    const ReturnHomeButton = () => {
        return(
            <Button variant="success" onClick={returnHome}>Return to login</Button>
        )
    };


    return (
        <div>
            <Container>
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                    <Card className="shadow">
                        <Card.Body>
                        <div className="mb-3 mt-md-4">
                            <div style={{textAlign: 'center'}}>
                                <Image 
                                src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Logos/nacca-color.png"
                                className="pb-4 pt-2" 
                                style={{height: 120}}
                                >
                                </Image>
                                <h1 className="display-6 pb-1">Reset password</h1>
                                {success ? <AlertSuccess /> : null}
                            </div>
                            <div className="mb-3">
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: 'red'}}>{errMsg}</p>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" style={success ? {display: 'none'} : {display: 'block'}}>
                                        <InputGroup>
                                        <InputGroup.Text><BsFillLockFill /></InputGroup.Text>
                                            <Form.Control
                                            type="password"
                                            id="new-password"
                                            placeholder="New password"
                                            autoComplete="off"
                                            onChange={(e) => {setPassword(e.target.value)}}
                                            value={password}
                                            required 
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-4" style={success ? {display: 'none'} : {display: 'block'}}>
                                        <InputGroup>
                                        <InputGroup.Text><BsFillLockFill /></InputGroup.Text>
                                            <Form.Control 
                                            type="password"
                                            id="confirm-password"
                                            placeholder="Confirm password"
                                            autoComplete="off"
                                            onChange={(e) => {setConfirmPassword(e.target.value)}}
                                            value={confirmPassword}
                                            required 
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                <div className="d-grid gap-2">
                                    {!success ? <ResetButton /> : null}
                                    {success ? <ReturnHomeButton /> : null}
                                </div>
                            </Form>
                                <hr />
                                <p className="text-center">
                                    <a href="https://fernsoftware.com/" target="blank" style={{ textDecoration: 'none' }}>Fern Software </a> 
                                    ©{new Date().getFullYear()}
                                </p>
                                <p className="text-center">
                                    <a href="https://fernsoftware.com/company/privacy" target="blank" style={{ textDecoration: 'none' }}>Privacy Policy </a>
                                    -
                                    <a href="https://fernsoftware.com/company/privacy" target="blank" style={{ textDecoration: 'none' }}> Terms of use</a>
                                </p>
                            </div>
                        </div>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export { ResetFormLoggedOut };



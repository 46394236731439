import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PaginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Spinner from 'react-bootstrap/Spinner';
import { Container, Row, Col, Button, Modal, Table, Alert } from 'react-bootstrap';
import Editable from 'react-bootstrap-editable';
import { BsFileEarmarkPersonFill } from "react-icons/bs";

const ApprovedTable = ( {approvees, loading, modalInfo, rowEvents, setModalInfo, show, setShow, handleClose,
    handleShow, isAdmin, setIsAdmin, SetBusinessName, SetDescription, SetOwnerName, businessType, SetBusinessType,
    SetPhone, SetEmail, SetWebsite, SetNaics, SetAddressLine1, SetAddressLine2, SetCity, SetProvince, SetPostalcode, 
    SetNumberOfEmployees, setIndigenousOwnership, ownerName, email, businessName, 
    description, phone, website, naics, addressLine1, addressLine2, city, province, postalCode, numberOfEmployees, 
    indigenousOwnership, awsUrl, setIsDirty, updateApprovedApplication, showUpdateSuccessAlert, showUpdateFailAlert,
    indigenousHeritage, setIndigenousHeritage} ) => {

    const { SearchBar } = Search;

    //Define columns
    const columns = [
        { dataField: "", text: "", csvExport: false,
        formatter: () => {return <Button variant="success" type="button">View</Button>},
        headerStyle: () => {return { width: "5%" };} },
        { dataField: "BusinessName", text: "Business name", headerStyle: () => {return { width: "24%" };} },
        { dataField: "OwnerName", text: "Owner name", hidden: true },
        { dataField: "BusinessType", text: "Business type", hidden: true },
        { dataField: "Email", text: "Business email", headerStyle: () => {return { width: "24%" };}  },
        { dataField: "Phone", text: "Phone", hidden: true },
        { dataField: "Website", text: "Website", hidden: true },
        { dataField: "AddressLine1", text: "Address line 1", hidden: true },
        { dataField: "AddressLine2", text: "Address line 2", hidden: true },
        { dataField: "City", text: "City", hidden: true },
        { dataField: "Province", text: "Location",headerStyle: () => {return { width: "18%" };}  },
        { dataField: "Postalcode", text: "Postal code", hidden: true },
        { dataField: "NAICS", text: "NAICS", headerStyle: () => {return {width: "24%"};} },
        { dataField: "NumberOfEmployees", text: "Number of employees", hidden: true },
        { dataField: "IndigenousHeritage", text: "Indigenous heritage", hidden: true },
        { dataField: "IndigenousOwnership", text: "% of Indigenous ownership", hidden: true },
        { dataField: "Description", text: "Description", hidden: true },
    ];

    const AdminView = () => {
        return(
            <Table striped>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td><strong>NAICS: </strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={naics.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetNaics(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><strong>Owner name:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={ownerName.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true)}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetOwnerName(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><strong>Business type:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={businessType.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true)}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetBusinessType(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td><strong>Address:</strong></td>
                        <td>
                            <span style={{display: 'inline-block'}}>
                            <Editable 
                            editText="Edit"
                            initialValue={addressLine1.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetAddressLine1(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                            </span>
                            ,
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={addressLine2.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetAddressLine2(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                            </span>
                            
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={city.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetCity(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                            </span>
                            ,
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={province.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetProvince(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                            </span>
                            ,
                            <span style={{display: 'inline-block', paddingLeft: 3}}>
                            <Editable 
                            editText="Edit"
                            initialValue={postalCode.toString()} 
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetPostalcode(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><strong>Email Address:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={email.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetEmail(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td><strong>Phone:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={phone.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetPhone(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td><strong>Website:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={website.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetWebsite(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td><strong>Number of employees:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={numberOfEmployees.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetNumberOfEmployees(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td><strong>Indigenous heritage:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={indigenousHeritage.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {setIndigenousHeritage(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td><strong>% Indigenous ownership:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={indigenousOwnership.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {setIndigenousOwnership(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td><strong>Description:</strong></td>
                        <td>
                            <Editable 
                            editText="Edit"
                            initialValue={description.toString()}
                            isValueClickable
                            mode="inline"
                            validate={(value) => {if(value) setIsDirty(true);}}
                            onValidated={(value) => {if(value) return}}
                            onSubmit={(value) => {SetDescription(value) && updateApprovedApplication()}}
                            type="textfield"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td><strong>Attachment:</strong></td>
                        <td>
                            {awsUrl ? <AttachmentButton /> : <p>No attachment on record</p>}
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    };

    const NotAdminView = () => {
        return(
            <Table striped>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td><strong>NAICS: </strong></td>
                            <td>{modalInfo.NAICS}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td><strong>Owner name: </strong></td>
                            <td>{modalInfo.OwnerName}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td><strong>Business type: </strong></td>
                            <td>{modalInfo.BusinessType}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td><strong>Address: </strong></td>
                            <td>{modalInfo.AddressLine1}, {modalInfo.AddressLine2}, {modalInfo.City}, {modalInfo.Province}, {modalInfo.Postalcode}</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td><strong>Email Address: </strong></td>
                            <td>{modalInfo.Email}</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td><strong>Phone: </strong></td>
                            <td>{modalInfo.Phone}</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td><strong>Website: </strong></td>
                            <td>{modalInfo.Website}</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td><strong>Number of employees: </strong></td>
                            <td>{modalInfo.NumberOfEmployees}</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td><strong>% Indigenous heritage: </strong></td>
                            <td>{modalInfo.IndigenousHeritage}</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td><strong>% Indigenous ownership: </strong></td>
                            <td>{modalInfo.IndigenousOwnership}</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td><strong>Description: </strong></td>
                            <td>{modalInfo.Description}</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td><strong>Attachment: </strong></td>
                            <td>{modalInfo.AttachmentURL ? <AttachmentButton /> : <p>No attachment on record</p>}  </td>
                        </tr>
                    </tbody>
                </Table>
        )
    };

    const AttachmentButton = () => {
        return (
           <Button variant="primary" type="button" href={modalInfo.AttachmentURL} target="_blank"><BsFileEarmarkPersonFill/> View</Button> 
        )
    };

    //CSV Export button
    const MyExportCSV = (props) => {
        const handleClick = () => {
          props.onExport();
        };
        return (
            <Button variant="success" className='export-button pull-right' onClick={ handleClick }>Export</Button>
        );
    };

    const UpdateSuccessAlert = () => {
        return(
            <Alert variant="success" style={{ display: showUpdateSuccessAlert ? "block" : "none" }}>
                Approved application has been successfully updated for {modalInfo.BusinessName}
            </Alert>
        )
    };
    const UpdateFailAlert = () => {
        return(
            <Alert variant="danger" style={{ display: showUpdateFailAlert ? "block" : "none" }}>
                An error has occurred while attempting to update the approved application for {modalInfo.BusinessName}
            </Alert>
        )
    };

    const ModalContent = () => {
        return (
            <Modal 
            show={show} 
            animation={false}
            scrollable={true}
            onHide={handleClose}
            size="lg" 
            centered
        >
            <UpdateSuccessAlert />
            <UpdateFailAlert />

            <Modal.Header closeButton>
                <Modal.Title> {modalInfo.BusinessName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container>
            {isAdmin ? <AdminView /> : <NotAdminView />} 
            </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        )
    }

    return(
    <>  
        {show ? <ModalContent /> : null}

        <ToolkitProvider
        
            keyField={"Id"}
            data={ approvees }
            columns={ columns }
            search
            exportCSV={{fileName: "Approved.csv"}}
        >  
            {
                props => (
                    <div>

                        <Container className="p-0">
                            <Row>
                                <Col xs={12} md={6}>
                                    <SearchBar { ...props.searchProps } />
                                </Col>
                                <Col xs={0} md={5}></Col>
                                <Col xs={12} md={1}>
                                    <MyExportCSV { ...props.csvProps } />
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        {loading ? ( <BootstrapTable
                            { ...props.baseProps }
                            pagination={PaginationFactory()}
                            rowEvents = {rowEvents}
                            wrapperClasses="table-responsive"
                        />) : (
                            <Container className="d-flex align-items-center justify-content-center">
                                <Spinner animation="border" variant="success" />
                            </Container>
                        )}
                    </div>
                )
                
            }
        </ToolkitProvider>
    </>  
    )
}

export { ApprovedTable }
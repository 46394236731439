import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import useAuth from "../hooks/useAuth";

const Footer = () => {

    const { auth } = useAuth();
    const role = auth.roles;

    const [viewAccessOnly, setViewAccessOnly] = useState(false);

    useEffect(() => {
        if(role === 3) {
          setViewAccessOnly(true);
        } else {
          setViewAccessOnly(false);
        }
    },[])

    return(
    <Container id="footer-container" fluid>
        <Container>
         <Row>
            <Col xs={6} md={3} style={{ display: viewAccessOnly ? "none" : "block" }}>
                <h4 className="footer-text mt-4 mb-2">Navigation</h4>
                <hr />
                <p><a href="/" className="footer-text">Invites</a> <br />
                <a href="/submitted" className="footer-text">Submissions</a> <br />
                <a href="/Approved" className="footer-text">Indigenous Businesses</a></p>
            </Col>
            <Col xs={6} md={3}>
                <h4 className="footer-text mt-4 mb-2">Contact</h4>
                <hr />
                <p className="footer-text">
                   338 Somerset Street West <br />
                   Ottawa, Ontario <br />
                   Canada <br />
                   K2P 0J9 <br />
                   <a href="https://nacca.ca/" className="footer-text">www.nacca.ca</a>
                </p>
            </Col>
            <Col xs={12} md={6}>
                <h4 className="footer-text mt-4 mb-2">About</h4>
                <hr />
                <p className="footer-text">The National Aboriginal Capital Corporations Association is a network of 
                        over 50 Indigenous Financial Institutions (IFIs) dedicated to stimulating economic growth for 
                        all Indigenous people in Canada. The IFI network has provided 50,000 loans totaling $3 billion 
                        to businesses owned by First Nations, Métis, and Inuit people. NACCA’s goal is to provide 
                        opportunities for Indigenous entrepreneurs and increase prosperity for Indigenous people in Canada.
                </p>
                <hr />
                <p className="footer-text">
                ©{new Date().getFullYear()}
                    <a href="https://fernsoftware.com/" target="blank" className="footer-text"> Fern Software </a> 
                </p>
            </Col>
            

        </Row>   
        </Container>
    </Container>
    )
}

export {Footer}

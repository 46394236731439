import React from "react";
import { Button, Alert, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const InviteInput = ({validated, handleSubmit, errors, showError, success, showSuccess, setShowError, setShowSuccess,
emails, setEmails}) => {


    const AlertError = () => {
        return(
            <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
            <Alert.Heading>Error</Alert.Heading>
                {errors.message}
            </Alert>
        )
    }

    return(
        <>
        {showError ? <AlertError /> : null}

        <Form noValidate validated={validated} onSubmit={handleSubmit} id="email-invite">
            <Form.Group className="mb-3">
            <Form.Label>Please enter the email address of the business(es) you wish to invite
                <br />
                <br />
                <strong>NOTE: You can add multiple email addresses</strong>
            </Form.Label>
                    <ReactMultiEmail
                        placeholder="Enter email(s)"
                        emails={emails}
                        onChange={(newValue) => {setEmails(newValue)}}
                        validateEmail={email => {
                            return isEmail(email); // return boolean
                        }}
                        getLabel={(
                            email,
                            index,
                            removeEmail) => {
                            return (
                            <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                                </span>
                            </div>
                            );
                        }}
                    />
                    <br />
                    <strong>Emails:</strong>
                    <p>{emails.join(', ') || 'empty'}</p>

            </Form.Group>
            <Button variant="primary" type="submit" >
                Send
            </Button>
        </Form>
        </>
    );
}

export { InviteInput }
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import {Button, InputGroup, Container, Row, Col, Card, Image} from 'react-bootstrap';
import { BsFillEnvelopeFill, BsFillLockFill } from "react-icons/bs";

const LoginForm = ({handleSubmit, username, setUsername, password, setPassword, userRef, errRef, errMsg, setErrMsg }) => {
    
    const navigate = useNavigate();

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const forgotPasswordClicked = () => {
        navigate('/forgot')
    };

    return (
        <div>
        <Container>
          <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
              <Card className="shadow">
                <Card.Body>
                  <div className="mb-3 mt-md-4">
                    <div style={{textAlign: 'center'}}>
                        <Image 
                        src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Logos/nacca-color.png"
                        className="pb-4 pt-2" 
                        style={{height: 120}}
                        >
                        </Image>
                        <h1 className="display-6 pb-3"><strong>Major Projects Indigenous Procurement Directory</strong></h1>
                        <h5 className="pb-3">Sign in to continue</h5>
                    </div>
                    <div className="mb-3">
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: 'red'}}>{errMsg}</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <InputGroup>
                                <InputGroup.Text><BsFillEnvelopeFill /></InputGroup.Text>
                                    <Form.Control
                                    type="email"
                                    id="username"
                                    autoComplete="on"
                                    placeholder="Email address"
                                    ref={userRef}
                                    onChange={(e) => {setUsername(e.target.value)}}
                                    value={username}
                                    required 
                                    />
                                </InputGroup>
                            </Form.Group>
    
                            <Form.Group className="mb-4">
                                <InputGroup>
                                <InputGroup.Text><BsFillLockFill /></InputGroup.Text>
                                    <Form.Control 
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                    autoComplete="off"
                                    onChange={(e) => {setPassword(e.target.value)}}
                                    value={password}
                                    required 
                                    />
                                </InputGroup>
                            </Form.Group>

                        <Form.Group className="mb-3">
                        <div className="d-grid gap-2" style={{textAlign: 'center'}}>
                            <Button variant="primary" type="submit">
                                Sign in
                            </Button>
                            <Button variant="link" className="pt-2" style={{ textDecoration: 'none'}} onClick={forgotPasswordClicked}>Forgot password?</Button>
                        </div>
                        </Form.Group>
                      </Form>
                        <hr />
                        <p className="text-center">
                            <a href="https://fernsoftware.com/" target="blank" style={{ textDecoration: 'none' }}>Fern Software </a> 
                            ©{new Date().getFullYear()}
                        </p>
                        <p className="text-center">
                            <a href="https://fernsoftware.com/company/privacy" target="blank" style={{ textDecoration: 'none' }}>Privacy Policy </a>
                            -
                            <a href="https://fernsoftware.com/company/privacy" target="blank" style={{ textDecoration: 'none' }}> Terms of use</a>
                        </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export { LoginForm };



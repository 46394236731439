import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PaginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Spinner from 'react-bootstrap/Spinner';
import Dayjs from 'dayjs';

const InviteTable = ({invites, loading, reminderButton}) => {

    const { SearchBar } = Search;

    const dateSentFormat = (cell, row, rowIndex) => {
        const dates = Dayjs(cell).format('YYYY-MM-DD');
        return dates;
    };

    //Define columns
    const columns = [
        { dataField: "ExpiresOn", text: "", csvExport: false,
            formatter: reminderButton,
        headerStyle: () => {return { width: "8%" };} },
        { dataField: "Recipient", text: "Recipient Email", headerStyle: () => {return { width: "30%" };}  },
        { dataField: "Created", text: "Date Sent", 
        formatter: dateSentFormat,
        headerStyle: () => {return { width: "20%" };}  },
        { dataField: "Status", text: "Status", 
        formatter: (cell) => cell = cell.toString().replace("0", "Pending").replace("1", "Submitted"),
        headerStyle: () => {return { width: "10%" };} }  
    ];

    //CSV Export button
    const MyExportCSV = (props) => {
        const handleClick = () => {
          props.onExport();
        };
        return (
            <Button variant="success" className='export-button pull-right' onClick={ handleClick }>Export</Button>
        );
    };

    return (
        <ToolkitProvider
        
            keyField={"Id"}
            data={ invites }
            columns={ columns }
            search
            exportCSV={{fileName: "Invites.csv"}}
        >
            {
                props => (
                    <div>
                        <Container className="p-0" fluid>
                            <Row>
                                <Col xs={12} md={6}>
                                    <SearchBar { ...props.searchProps } />
                                </Col>
                                <Col xs={0} md={5}></Col>
                                <Col xs={12} md={1}>
                                    <MyExportCSV { ...props.csvProps } />
                                </Col>
                            </Row>
                        </Container>
                        <hr />
                        {loading ? ( <BootstrapTable
                            { ...props.baseProps }
                            pagination={PaginationFactory()}
                            wrapperClasses="table-responsive"
                        />) : (
                            <Container className="d-flex align-items-center justify-content-center">
                                <Spinner animation="border" variant="success" />
                            </Container>
                        )}
                    </div>
                )
                
            }
        </ToolkitProvider>
    )
};

export { InviteTable }
import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from "../hooks/useAuth";

import { Container, Row, Col, Image, Button, Badge, Alert } from 'react-bootstrap';
import { InviteInput } from '../Components/InviteInput';
import { InviteTable } from '../Components/InviteTable';
import { NavBar } from '../Components/NavBar';
import { Footer } from '../Components/Footer';
import Dayjs from 'dayjs';

const Invite = () => {

    const [invites, setInvites] = useState([]);
    const [loading, setLoading] = useState(false);

    const { auth } = useAuth();
    const userId = auth.userId;
    const userEmail = auth.username;
    const axiosPrivate = useAxiosPrivate();
    const url = window.location.href;

    const getInvites = "/invites";
    const postInvites = "/invites/postinvites";
    const inviteEmail = "/email/invite";
    const reminderEmail = "/email/reminder";
    
    const [emails, setEmails] = useState([]); //multi email array

    const [validated, setValidated] = useState(false);
    const [dateTime, setDateTime] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [code, setCode] = useState('');
    const [status, setStatus] = useState('');

    const [errors, setErrors] = useState([]);
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);

    const getInviteData = async () => {
        try { 
            const response = await axiosPrivate.get(getInvites, {});
            setInvites(response.data);
            setLoading(true);
        } catch (e) {
            console.log(e)
        }
    };

    //Getting Invite Data on page load
    useEffect(() => {
        const abortController = new AbortController();

        getInviteData();

        return () => {
            abortController.abort()
          }
    }, [])

    //Handle submit of Invite + Email
    const handleSubmit = async (event) => {
       
        event.preventDefault();
        event.stopPropagation();
        setShowError(false);
        
        [...emails].forEach(email => {
        const newInvite = {Recipient: email, Created: dateTime, ExpiresOn: expireDate, Code: code, Status: status}
            axiosPrivate.post(postInvites, newInvite)
            .then((res) => {
                if(res.status === 200) {
                    setValidated(false);
                    setSuccess(res.data);
                    console.log(res.data);
                    setShowSuccess(true, setTimeout(() => {
                        setShowSuccess(false);
                    }, 5000));
                    getInviteData();
                    const allInvites = [...invites, newInvite];
                    setInvites(allInvites);
                    axiosPrivate.post(inviteEmail, {
                        InviteId: res.data.InviteId,
                        Code: res.data.Code,
                        Recipient: email,
                        Url: url,
                        Email: userEmail //this is the logged in users email address
                    });
                    setEmails([]);
                }
            })
            .catch((error) => {
                setErrors(error.response.data);
                setShowError(true);
                setShowSuccess(false);
            });
        });
    };

    const AlertSuccess = () => {
        return(
            <>
            <br/>
            <Image 
                src="https://sky-alyne.s3.ca-central-1.amazonaws.com/mail+sent.png"
                className="pb-4 pt-2" 
                style={{height: 160, alignContent: 'center'}}
                >
            </Image>
            {success.message}    
            </>
        )
    };

    const reminderButton = (cell, row, rowIndex) => {
        const reminderDate = Dayjs(cell).add(1, 'day').format('YYYY-MM-DD');
        let todayDate = Dayjs().format('YYYY-MM-DD');
        const inviteEmailsSent = row.InviteEmailsSent;

        const setReminderEmailInfo = () => {
            const id = row.Id;
            const recipient = row.Recipient;
            const code = row.Code;
            const url = window.location.href;

            axiosPrivate.put(`/invites/updateinvite/${id}`, {
                UserId: userId,
            }).then((res) => {
                if(res.status === 200) {
                    getInviteData();
                    axiosPrivate.post(reminderEmail, {
                        InviteId: id,
                        Code: code,
                        Recipient: recipient,
                        Url: url,
                        Email: userEmail //this is the logged in users email address
                    }); 
                }
            }).catch((error) => {
                console.log(error);
            });  
        };

        if(inviteEmailsSent < 2) {
            if(todayDate > reminderDate) {
            return (
                <Button variant="primary" type="button" size="sm" onClick={setReminderEmailInfo}>Send reminder</Button>
            )  
            } else {
                return;
            }  
        } else if(inviteEmailsSent === 2) {
            return (
                <Badge pill bg="success">
                    Reminder sent
                </Badge>
            )
        }
        else {
            return;
        }
    };


    return(
        <div>
            <NavBar />
            <Container className="contentContainer pt-5 pb-5" style={{backgroundColor: 'white'}}>
            <Row className="mb-5">
                <Alert variant="secondary">
                    <Alert.Heading>The Major Projects Indigenous Procurement Directory will:</Alert.Heading>
                    <hr />
                    <ul>
                        <li>Be a place for Corporate Canada and Government Agencies can learn more about Indigenous business products and services</li>
                        <li>Be a secure site where only for Indigenous Businesses and Major Project Buyers can connect,</li>
                        <li>Be a place for Corporate Canada and Government Agencies can promote their major projects and contact Indigenous businesses for future work, and</li>
                        <li>No cost to join the directory</li>
                    </ul> 
                    
                </Alert>
                </Row>
                <h2 className='display-6 mb-4'>Invite Business</h2>
                <Row className="mb-5">
                    <Col xs={12} md={6}>
                        <InviteInput 
                            validated={validated}
                            handleSubmit={handleSubmit}
                            errors={errors}
                            showError={showError}
                            success={success}
                            showSuccess={showSuccess}
                            setShowError={setShowError}
                            setShowSuccess={setShowSuccess}
                            emails={emails}
                            setEmails={setEmails}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        {showSuccess ? <AlertSuccess /> : null}
                    </Col>
                </Row>
                <Row>
                    <h3 className='display-6 mb-4'>Pending Invitations</h3>
                    <InviteTable 
                        invites={invites}
                        loading={loading}
                        reminderButton={reminderButton}
                    />
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export { Invite }


import React, {useState} from 'react';
import { Button, Image } from 'react-bootstrap';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
const NaicsList = require('../Assets/NAICS.json');

const ApplicationForm = ({SetBusinessName, SetDescription, SetOwnerName, SetBusinessType, SetPhone, SetEmail, SetWebsite, SetNaics, SetAddressLine1,
    SetAddressLine2, SetCity, SetProvince, SetPostalcode, SetNumberOfEmployees, setIndigenousOwnership, SetIndigenousHeritage, handleSubmit, validated,
    success, showSuccess, uploadInput, naics, setNaicsError, naicsError}) => {

    const [showIndianStatusCard, setShowIndianStatusCard] = useState(false);
    const [showMetisMembershipCard, setShowMetisMembershipCard] = useState(false);
    const [showInuitBeneificiaryCard, setShowInuitBeneificiaryCard] = useState(false);

    const options = NaicsList.map((item) => ({
        value: item.Code + " - " + item.Title,
        label: item.Code + " - " + item.Title
    }));

    const handleChange = (s) =>{
        SetNaics((s[0] ? s[0].label : "") + '')
        setNaicsError(!naics ? '' : 'Please choose NAICS from the dropdown list');
    };

    const onKeyUpPhone = (e) => {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    };

    const handleHeritageChange = (e) => {
        SetIndigenousHeritage(e.target.value);
        if (e.target.value === 'First Nations') {
            setShowIndianStatusCard(true);
            setShowMetisMembershipCard(false);
            setShowInuitBeneificiaryCard(false);
        } else if(e.target.value === 'Metis') {
            setShowMetisMembershipCard(true);
            setShowIndianStatusCard(false);
            setShowInuitBeneificiaryCard(false);
        } else if(e.target.value === 'Inuit') {
            setShowInuitBeneificiaryCard(true);
            setShowIndianStatusCard(false);
            setShowMetisMembershipCard(false);
        } else {
            setShowInuitBeneificiaryCard(false);
            setShowIndianStatusCard(false);
            setShowMetisMembershipCard(false);
        }
    };

    return(
        <>        
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container className="pt-5 pb-2" style={{backgroundColor: 'white'}}>
            <Row className="pb-2">
                <Col xs={3} md={2}></Col>
                <Col xs={12} md={8} style={{textAlign: 'center'}}><h1 className="display-6">Major Projects Indigenous Procurement Directory</h1></Col>
                <Col xs={3} md={2}></Col>
            </Row>
            <Row className='pb-4'>
                <Col></Col>
                <Col xs={12}style={{textAlign: 'center'}}>
                    <Image 
                        src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Logos/nacca-color.png"
                        className="pb-5 pt-2" 
                        style={{height: 180, alignContent: 'center'}}
                        >
                    </Image>
                </Col>
                <Col></Col>
            </Row>
            <Row className='pt-2 pb-3' style={{ display: showSuccess ? "none" : "block" }}>
                <Alert variant="success">
                    <Alert.Heading>Hey, nice to see you!</Alert.Heading>
                    <p>
                        Please fill out the application form with your business details in order to apply. Someone will be in touch soon with a decision for your application.
                    </p>
                </Alert>
            </Row>
            <Container fluid style={{ display: showSuccess ? "block" : "none" }}>
                <Row>
                    <Col></Col>
                    <Col>
                    <img
                        src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Completed.png"
                        alt="Success"
                        width="305"
                        height="250"
                    />
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={6}><h5 className='mb-4'>{success.message}</h5></Col>
                    <Col xs={12} md={3}></Col>
                </Row>
            </Container>
            <div style={{ display: showSuccess ? "none" : "block" }}>
                <h3 className='pb-3'>Business Information<hr/></h3>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Business name<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Control type="text" placeholder="Your business name" name='businessName' onChange={(e) => {SetBusinessName(e.target.value);}} required/>
                            <Form.Control.Feedback type="invalid">Please input your Business name</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Owner name<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Control type="text" placeholder="Business owner name" name='ownerName' onChange={(e) => {SetOwnerName(e.target.value);}} required/>
                            <Form.Control.Feedback type="invalid">Please input the business owners name</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Business description<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Control as="textarea" rows={4} type="text" name='description' placeholder="Describe your business" onChange={(e) => {SetDescription(e.target.value);}} required/>
                            <Form.Control.Feedback type="invalid">Please provide a business description</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                <Col xs={6} md={4}>
                <Form.Label>Business Type<span style={{color: 'red'}}> *</span></Form.Label>
                    <Form.Select aria-label="business type" name='business-type' onChange={(e) => {SetBusinessType(e.target.value);}} required>
                        <option hidden value=''>Choose business type</option>
                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Incorporated Business">Incorporated Business</option>
                        <option value="Joint Venture">Joint Venture</option>
                        <option value="Community-owned Business">Community-owned Business</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Please choose the business type from the dropdown</Form.Control.Feedback>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>NAICS<span style={{color: 'red'}}> *</span></Form.Label>
                        <Typeahead 
                            id="naics-select"
                            inputProps={{ required: true }}
                            options={options}
                            onChange={handleChange}
                            isInvalid={!!naicsError}
                        />
                        <Form.Control.Feedback type="invalid">{naicsError}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Number of employees<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="number" placeholder="# of employees" name='numberOfEmployees' onChange={(e) => {SetNumberOfEmployees(e.target.value);}} required/>
                        <Form.Control.Feedback type="invalid">Please specify the number of current employees</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Phone number<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="tel" placeholder="" id='phone' name='phone' onKeyUp={onKeyUpPhone} maxLength="14" onChange={(e) => {SetPhone(e.target.value);}} required/>
                        <Form.Control.Feedback type="invalid">Please list business contact number</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Email address<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="email" placeholder="Enter email"  name='email' onChange={(e) => {SetEmail(e.target.value);}} required/>
                        <Form.Control.Feedback type="invalid">Please input a correct email address</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" placeholder="www.yourbusiness.ca" name='website' onChange={(e) => {SetWebsite("https://" + e.target.value);}}/>
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="">
                        <Form.Label>% Indigenous Ownership<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="number" placeholder="% indigenous ownership" name='indigenousOwnership' onChange={(e) => {setIndigenousOwnership(e.target.value);}} required/>
                        <Form.Control.Feedback type="invalid">Please specify the % of indigenous ownership</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Label>Indigenous heritage<span style={{color: 'red'}}> *</span></Form.Label>
                    <Form.Select aria-label="indigenous-heritage" name='indigenous-heritage' onChange={handleHeritageChange} required>
                        <option></option>
                        <option value="First Nations">First Nations</option>
                        <option value="Metis">Metis</option>
                        <option value="Inuit">Inuit</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Please choose your indigenous heritage from the dropdown list</Form.Control.Feedback>
                </Col>

                <Col xs={6} md={4}>
                    {showIndianStatusCard ? 
                    <Form.Group className="mb-3" >
                        <Form.Label>Indian Status Card<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="file" ref={uploadInput} required/>
                        <Form.Control.Feedback type="invalid">Please upload Indian status card attachment</Form.Control.Feedback>
                    </Form.Group> 
                    : null}

                    {showMetisMembershipCard ? 
                    <Form.Group className="mb-3">
                        <Form.Label>Metis Membership Card<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="file" ref={uploadInput} required/>
                        <Form.Control.Feedback type="invalid">Please upload Metis membership card attachment</Form.Control.Feedback>
                    </Form.Group>
                    : null}

                    {showInuitBeneificiaryCard ? 
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Inuit Beneificiary Card<span style={{color: 'red'}}> *</span></Form.Label>
                        <Form.Control type="file" ref={uploadInput} required/>
                        <Form.Control.Feedback type="invalid">Please upload Inuit beneificiary card attachment</Form.Control.Feedback>
                    </Form.Group>
                    : null}
                </Col>

                </Row>
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Address Line 1<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Control type="text" placeholder="Line 1" name='addressLine1' onChange={(e) => {SetAddressLine1(e.target.value);}} required/>
                            <Form.Control.Feedback type="invalid">Please input line 1 of your business address</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control type="text" placeholder="Optional" name='addressLine2' onChange={(e) => {SetAddressLine2(e.target.value);}} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>City<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Control type="text" placeholder="" name='city' onChange={(e) => {SetCity(e.target.value);}} required/>
                            <Form.Control.Feedback type="invalid">Please specify the business address City</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Province<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Select aria-label="province" name='province' onChange={(e) => {SetProvince(e.target.value);}} required>
                            <option></option>
                            <option value="Alberta">Alberta</option>
                            <option value="British Columbia">British Columbia</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="New Brunswick">New Brunswick</option>
                            <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                            <option value="Nova Scotia">Nova Scotia</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Prince Edward Island">Prince Edward Island</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            </Form.Select>                            
                            <Form.Control.Feedback type="invalid">Please choose the business address province</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Postal code<span style={{color: 'red'}}> *</span></Form.Label>
                            <Form.Control type="text" placeholder="" pattern='^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$' name='postalCode' onChange={(e) => {SetPostalcode(e.target.value);}} required/>
                            <Form.Control.Feedback type="invalid">Please input the business address postal code in the correct format</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            <Button variant="primary" type="submit">
                Submit
            </Button>
            </div>
            </Container>
        </Form>
        </>
    );
}

export { ApplicationForm }
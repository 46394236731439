import React, { useState, useEffect} from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Container, Row } from 'react-bootstrap';
import { ApprovedTable } from '../Components/ApprovedTable';
import { NavBar } from '../Components/NavBar';
import { Footer } from '../Components/Footer';
import useAuth from "../hooks/useAuth";

const Approved = () => {

    const [approvees, setApprovees] = useState([]);
    const [loading, setLoading] = useState(false);
    const url = "/applications/approved";
    const { auth } = useAuth();
    const userId = auth.userId;
    const roleId = auth.roles;

    const axiosPrivate = useAxiosPrivate();
    const [isAdmin, setIsAdmin] = useState(false);

    //Modal stuff
    const [modalInfo, setModalInfo] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isDirty, setIsDirty] = useState(false);

    //Editable fields
    const [businessName, SetBusinessName] = useState();
    const [description, SetDescription] = useState();
    const [ownerName, SetOwnerName] = useState();
    const [businessType, SetBusinessType] = useState();
    const [phone, SetPhone] = useState();
    const [email, SetEmail] = useState();
    const [website, SetWebsite] = useState();
    const [naics, SetNaics] = useState();
    const [addressLine1, SetAddressLine1] = useState();
    const [addressLine2, SetAddressLine2] = useState();
    const [city, SetCity] = useState();
    const [province, SetProvince] = useState();
    const [postalCode, SetPostalcode] = useState();
    const [numberOfEmployees, SetNumberOfEmployees] = useState();
    const [indigenousHeritage, setIndigenousHeritage] = useState();
    const [indigenousOwnership, setIndigenousOwnership] = useState();
    const [awsUrl, setAwsUrl] = useState();

    //Alert actions for updating a submitted application
    const [showUpdateSuccessAlert, SetShowUpdateSuccessAlert] = useState(false);
    const [showUpdateFailAlert, SetShowUpdateFailAlert] = useState(false);

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(url, {});
            setApprovees(response.data);
            setLoading(true);
        } catch (e) {
            console.log(e)
        }
    };

    const checkIsAdmin = () => {
        if(roleId === 1 || modalInfo.UserId === userId) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    };

    useEffect(() => {
        const abortController = new AbortController();

        getApprovedData();
        checkIsAdmin();

        return () => {
            abortController.abort()
        }
    }, [modalInfo])

    const updateApprovedApplication = async (e) => {
        const id = modalInfo.Id;
        const updatedSubmit = {BusinessName: businessName, Description: description, OwnerName: ownerName, Phone: phone,
        Email: email, Website: website, NAICS: naics, AddressLine1: addressLine1, AddressLine2: addressLine2, City: city,
        Province: province, PostalCode: postalCode, NumberOfEmployees: numberOfEmployees,IndigenousOwnership: indigenousOwnership}

        try {
           axiosPrivate.put(`/applications/update/${id}`, updatedSubmit)
            .then((res) => {
                if(res.status === 200) {
                    getApprovedData();
                    SetShowUpdateSuccessAlert(true, setTimeout(() => {
                        SetShowUpdateSuccessAlert(false);
                    }, 5000))
                } else {
                    SetShowUpdateFailAlert(true, setTimeout(() => {
                        SetShowUpdateFailAlert(false);
                    }, 8000))
                }
            });  
        } catch (err) {
            if (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {

        if(isDirty) {
            updateApprovedApplication();
        }
        
        return(
            setIsDirty(false)
        )

    }, [isDirty])

    const toggleTrueFalse = () => {
        setShow(handleShow);
    };

    const rowEvents = {
        onClick: (e, row) => {
            setIsDirty(false);
            setModalInfo(row);
            toggleTrueFalse();
            SetOwnerName(row.OwnerName);
            SetBusinessType(row.BusinessType);
            SetEmail(row.Email);
            SetDescription(row.Description);
            SetBusinessName(row.BusinessName);
            SetPhone(row.Phone);
            SetWebsite(row.Website);
            SetNaics(row.NAICS);
            SetAddressLine1(row.AddressLine1);
            SetAddressLine2(row.AddressLine2);
            SetCity(row.City);
            SetProvince(row.Province);
            SetPostalcode(row.Postalcode);
            SetNumberOfEmployees(row.NumberOfEmployees);
            setIndigenousHeritage(row.IndigenousHeritage);
            setIndigenousOwnership(row.IndigenousOwnership);
            setAwsUrl(row.AttachmentURL);
        },
    };

    return (
        <>
            <NavBar />
            <Container className="contentContainer pt-5 pb-5" style={{backgroundColor: 'white'}}>
                <Row>
                    <h3 className='display-6 mb-4'>Indigenous Businesses</h3>
                    <ApprovedTable 
                        approvees={approvees}
                        setApprovees={setApprovees}
                        loading={loading}
                        setLoading={setLoading}
                        modalInfo={modalInfo}
                        setModalInfo={setModalInfo}
                        show={show}
                        setShow={setShow}
                        handleClose={handleClose}
                        handleShow={handleShow}
                        setIsAdmin={setIsAdmin}
                        isAdmin={isAdmin}
                        rowEvents={rowEvents}
                        SetBusinessName={SetBusinessName}
                        businessName={businessName}
                        SetDescription={SetDescription}
                        description={description}
                        SetOwnerName={SetOwnerName}
                        ownerName={ownerName}
                        businessType={businessType}
                        SetBusinessType={SetBusinessType}
                        SetPhone={SetPhone}
                        phone={phone}
                        SetEmail={SetEmail}
                        email={email}
                        SetWebsite={SetWebsite}
                        website={website}
                        SetNaics={SetNaics}
                        naics={naics}
                        SetAddressLine1={SetAddressLine1}
                        addressLine1={addressLine1}
                        SetAddressLine2={SetAddressLine2}
                        addressLine2={addressLine2}
                        SetCity={SetCity}
                        city={city}
                        SetProvince={SetProvince}
                        province={province}
                        SetPostalcode={SetPostalcode}
                        postalCode={postalCode}
                        SetNumberOfEmployees={SetNumberOfEmployees}
                        numberOfEmployees={numberOfEmployees}
                        setIndigenousHeritage={setIndigenousHeritage}
                        indigenousHeritage={indigenousHeritage}
                        setIndigenousOwnership={setIndigenousOwnership}
                        indigenousOwnership={indigenousOwnership}
                        awsUrl={awsUrl}
                        setIsDirty={setIsDirty}
                        updateApprovedApplication={updateApprovedApplication}
                        showUpdateSuccessAlert={showUpdateSuccessAlert}
                        showUpdateFailAlert={showUpdateFailAlert}
                    />
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export { Approved }


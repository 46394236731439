import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';

const PersistLogin = () => {

    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    const verifyRefreshToken = async () => {
        try {
            await refresh();
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    }, []) 

    return (
        <>
            {isLoading
                ? <>
                  <Container className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" variant="success" className='' style={{position: "fixed", top: "50%", left: "50%"}}/>
                  </Container>
                  </>
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin;
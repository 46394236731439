import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

export const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <Container className="pt-5 pb-5 " style={{backgroundColor: 'white'}}>
            <Row>
                <Col xs={1}></Col>
                <Col xs={10} style={{textAlign: 'center'}}>
                    <h1>Unauthorized</h1>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col xs={12} style={{textAlign: 'center'}}>
                    <Image src='https://sky-alyne.s3.ca-central-1.amazonaws.com/access_denied.png' className="pb-5 pt-5" 
                    style={{width: 400}}></Image>
                    <p>
                        You do not have access to the requested page. If you require access to this page, please contact 
                        <a href="mailto:support@faasbank.ca"> support@faasbank.ca</a>
                    </p>
                    <Button onClick={goBack}>Go Back</Button>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default Unauthorized
import { Outlet } from "react-router-dom"

const Layout = () => {
    return (
        <main className="App">
             <Outlet /> {/*All the children in layout component - Anything nested inside Layout component is represented by the outlet */}
        </main>
    )
}

export {Layout}
import { React, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import axios from '../api/axios';
import { ResetFormLoggedOut } from '../Components/ResetPasswordFormLoggedOut';
import '../App.css';

const ResetLoggedOut = () => {

    const axiosPrivate = useAxiosPrivate();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const navigate = useNavigate();

    const errRef = useRef();
    const successRef = useRef();
    const pwRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    let capitalLettersRegex = /[A-Z]+/;
    let numberRegex = /[0-9]+/;
    let specialCharacterRegex = /[^A-Za-z0-9]+/;
    let token = query.get('token');

    useEffect(() => {
        getResetToken()
    }, [])

    const getResetToken = async () => {
        try { 
            const response = await axiosPrivate.post(`/forgotpassword/findtoken`, {
                resetToken: token
            })
            if(response.status === 200) {
                setIsLoading(false);
            }
        } catch (e) {
            if(e.response.status === 404) {
                navigate("/passwordresetexpired") 
            }
            if(e.response.status === 401) {
                navigate("/passwordresetexpired") 
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(password !== confirmPassword){
            setErrMsg("Passwords do not match");
        } else if(!password) {
            setErrMsg("Please enter a new password")
        } else if(password.length < 8) {
            setErrMsg("Password cannot be less than 8 characters")
        } else if(!password.match(capitalLettersRegex)) {
            setErrMsg("Password must contain at least 1 capital letter")
        } else if(!password.match(numberRegex)) {
            setErrMsg("Password must contain at least 1 number")
        } else if(!password.match(specialCharacterRegex)) {
            setErrMsg("Password must contain at least 1 special character")
        }
        else {
          try {
            const response = await axiosPrivate.put(`/reset/${token}`, 
                {
                ConfirmPassword: confirmPassword, 
                PasswordHash: password,
                }
            )
            setSuccess(response.data);
            setShowSuccessAlert(true);
            setConfirmPassword('');
            setPassword('');
            } catch (err){
                if (!err?.response) {
                    setErrMsg('No Server Response');
                }
                else if(err.response.status === 404) {
                    navigate("/invalidinvite")
                }
                else if(err){
                    setErrMsg(err.response.data.message)
                } else {
                    setErrMsg('Reset Failed');
                }
                errRef.current.focus();
            }   
        }
    };

    return (
        <>
        {isLoading
                ? <>
                  <Container className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" variant="success" className='' style={{position: "fixed", top: "50%", left: "50%"}}/>
                  </Container>
                  </>
                : 
                <div className="loginBackground">
                    <ResetFormLoggedOut
                        handleSubmit={handleSubmit}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        password={password}
                        setPassword={setPassword}
                        errRef={errRef}
                        successRef={successRef}
                        pwRef={pwRef}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                        showSuccessAlert={showSuccessAlert}
                        success={success}
                    />
                </ div>
        }
        </>
);
}

export { ResetLoggedOut };



import RequireAuth from './Components/RequireAuth';
import PersistLogin from './Components/PersistLogin';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './Components/Layout';
import { Invite } from './Pages/Invite';
import { Submitted } from './Pages/Submitted';
import { Approved } from './Pages/Approved';
import { Login } from './Pages/Login';
import { ResetLoggedOut } from './Pages/ResetPasswordLoggedOut';
import { ForgotPassword } from './Pages/ForgotPassword';
import { Application } from './Pages/Application';
import { Unauthorized } from './Components/Errors/Unauthorized';
import { InvalidInvite } from './Components/Errors/InvalidInvite';
import { InviteAlreadyResponded } from './Components/Errors/InviteAlreadyResponded';
import { PasswordResetExpired } from './Components/Errors/PasswordResetExpired';

import './App.css';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public routes*/}
        <Route exact path='Login' element={<Login/>}  />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="invalidinvite" element={<InvalidInvite />} />
        <Route path="inviteresponded" element={<InviteAlreadyResponded />} />
        <Route path="passwordresetexpired" element={<PasswordResetExpired />} />
        <Route path='Application' element={<Application />}  />
        <Route path="forgot" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<ResetLoggedOut />} /> {/* Logged out password reset */}
        
        {/* Protected routes*/}

        <Route element={ <PersistLogin /> }>
          <Route element={<RequireAuth allowedRoles={[1, 2]}/>}> 
            <Route exact path="/" element={<Invite/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[1, 2]}/>}> 
            <Route exact path='submitted' element={<Submitted/>} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[1, 2, 3]}/>}> 
            <Route exact path='approved' element={<Approved/>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;


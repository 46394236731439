import { React, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import axios from '../api/axios';
import useAuth from "../hooks/useAuth";
import { LoginForm } from '../Components/LoginForm';
import '../App.css';

const Login = () => {

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/Approved";

    const errRef = useRef();
    const userRef = useRef();
    const LOGIN_URL = "/auth";
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errMsg, setErrMsg] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const image = document.createElement("img");
    image.src = "https://major-projects-directory.s3.ca-central-1.amazonaws.com/Images/CloudyForestBackground.jpg";

    useEffect(() => {
        image.onload = () => {
            setIsLoading(false);
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL, 
                {
                    Email: username, 
                    PasswordHash: password,
                }, 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            const userId = response?.data.userId;
            setAuth({ username, password, roles, accessToken, userId });
            setUsername('');
            setPassword('');
            if(roles === 3) {
                navigate('/approved')
            } else {
                navigate(from, { replace: true });  
            }
        } catch (err){
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized login attempt');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }  
    };

    return (
        <>
        {isLoading
            ? <>
              <Container className="d-flex align-items-center justify-content-center">
                <Spinner animation="border" variant="success" className='' style={{position: "fixed", top: "50%", left: "50%"}}/>
              </Container>
              </>
            :
            <div className="loginBackground" style={{backgroundImage: `url(${image.src})`}}>
                <LoginForm
                    handleSubmit={handleSubmit}
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                    errRef={errRef}
                    userRef={userRef}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                />
            </ div>
        }
    </>
    );
}

export { Login };



import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    const theRoles = [auth.roles];

    return(
        theRoles?.find(role => allowedRoles?.includes(role))
         ? <Outlet />  //This outlet represents any child components of the RequireAuth method
        : auth?.username
        ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
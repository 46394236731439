import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { SubmittedTable } from '../Components/SubmittedTable';
import { NavBar } from '../Components/NavBar';
import { Footer } from '../Components/Footer';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from "../hooks/useAuth";


const Submitted = () => {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const userEmail = auth.username;
    const url = window.location.host;

    const [submitted, setSubmitted] = useState([]);
    const [loading, setLoading] = useState(false);

    //Modal stuff
    const [modalInfo, setModalInfo] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [isDirty, setIsDirty] = useState(false);

    //Editable fields
    const [businessName, SetBusinessName] = useState();
    const [description, SetDescription] = useState();
    const [ownerName, SetOwnerName] = useState();
    const [businessType, SetBusinessType] = useState();
    const [phone, SetPhone] = useState();
    const [email, SetEmail] = useState();
    const [website, SetWebsite] = useState();
    const [naics, SetNaics] = useState();
    const [addressLine1, SetAddressLine1] = useState();
    const [addressLine2, SetAddressLine2] = useState();
    const [city, SetCity] = useState();
    const [province, SetProvince] = useState();
    const [postalCode, SetPostalcode] = useState();
    const [numberOfEmployees, SetNumberOfEmployees] = useState();
    const [indigenousHeritage, setIndigenousHeritage] = useState();
    const [indigenousOwnership, setIndigenousOwnership] = useState();
    const [awsUrl, setAwsUrl] = useState();

    //Alert actions for updating a submitted application
    const [showUpdateSuccessAlert, SetShowUpdateSuccessAlert] = useState(false);
    const [showUpdateFailAlert, SetShowUpdateFailAlert] = useState(false);

    //Alert actions after approval
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showRejectAlert, setShowRejectAlert] = useState(false);
    const [showModalBody, setShowModalBody] = useState(true);
    const [showOkayButton, setShowOkayButton] = useState(false);
    const [showModalButtons, setShowModalButtons] = useState(true);

    const getSubmittedData = async () => {
        try {
            const response = await axiosPrivate.get('/applications/submitted', {});
            setSubmitted(response.data);
            setLoading(true);
        } catch (e) {
            console.log(e)
        }
    };

    //Get and set submitted data for table
    useEffect(() => {
        const abortController = new AbortController();

        getSubmittedData(); 

        return () => {
            abortController.abort()
        }
    }, [])

    
    //Toggle Modal visibility
    const toggleTrueFalse = () => {
        setShow(handleShow);
    };

    //Clicking on table row
    const rowEvents = {
        onClick: (e, row) => {
            setIsDirty(false);
            setModalInfo(row);
            toggleTrueFalse();
            SetOwnerName(row.OwnerName);
            SetBusinessType(row.BusinessType);
            SetEmail(row.Email);
            SetDescription(row.Description);
            SetBusinessName(row.BusinessName);
            SetPhone(row.Phone);
            SetWebsite(row.Website);
            SetNaics(row.NAICS);
            SetAddressLine1(row.AddressLine1);
            SetAddressLine2(row.AddressLine2);
            SetCity(row.City);
            SetProvince(row.Province);
            SetPostalcode(row.Postalcode);
            SetNumberOfEmployees(row.NumberOfEmployees);
            setIndigenousHeritage(row.IndigenousHeritage);
            setIndigenousOwnership(row.IndigenousOwnership);
            setAwsUrl(row.AttachmentURL);
        },
    };
    

    const updateSubmittedApplication = async (e) => {
        const id = modalInfo.Id;
        const updatedSubmit = {BusinessName: businessName, Description: description, OwnerName: ownerName, BusinessType: businessType, Phone: phone,
        Email: email, Website: website, NAICS: naics, AddressLine1: addressLine1, AddressLine2: addressLine2, City: city,
        Province: province, PostalCode: postalCode, NumberOfEmployees: numberOfEmployees, IndigenousHeritage: indigenousHeritage,
        IndigenousOwnership: indigenousOwnership}

        try {
           axiosPrivate.put(`/applications/update/${id}`, updatedSubmit)
            .then((res) => {
                if(res.status === 200) {
                    getSubmittedData();
                    SetShowUpdateSuccessAlert(true, setTimeout(() => {
                        SetShowUpdateSuccessAlert(false);
                    }, 5000))
                } else {
                    SetShowUpdateFailAlert(true, setTimeout(() => {
                        SetShowUpdateFailAlert(false);
                    }, 8000))
                }
            });  
        } catch (err) {
            if (err) {
                console.log(err);
            }
        }
    };


    useEffect(() => {

        if(isDirty) {
            updateSubmittedApplication();
        }
        
        return(
            setIsDirty(false)
        )

    }, [isDirty])


    //Reject a submission
    const rejectApplication = () => {
        const id = modalInfo.Id;
        axiosPrivate.put(`/applications/reject/${id}`);
        setShowModalBody((s) => !s);
        setShowModalButtons((s) => !s);
        setShowOkayButton((s) => !s);
        setShowRejectAlert((s) => !s);
        // window.location.reload();
    }

    //Approve a submission
    const approveApplication = () => {
        const id = modalInfo.Id;
        const businessName = modalInfo.BusinessName;
        const email = modalInfo.Email;
        axiosPrivate.put(`/applications/approve/${id}`)
        .then((res) => {
            axiosPrivate.post("/email/approved", {
                BusinessName: businessName,
                Url: url,
                Email: userEmail //this is the logged in users email address
            });
            axiosPrivate.post("/email/approvedbusiness", {
                BusinessName: businessName,
                Email: email,
                UserEmail: userEmail
            });
            console.log(res.data);
            setShowModalBody((s) => !s);
            setShowModalButtons((s) => !s);
            setShowOkayButton((s) => !s);
            setShowSuccessAlert((s) => !s);
        }).catch((error) => {
            console.log(error.response.data); 
        });
    }


    return (
        <div>
            <NavBar />
                <Container className="contentContainer pt-5 pb-5" style={{backgroundColor: 'white'}}>
                    <Row>
                        <h3 className='display-6 mb-4 mt-4'>Submitted Applications</h3>
                        <SubmittedTable 
                            submitted={submitted}
                            loading={loading}
                            modalInfo={modalInfo}
                            setModalInfo={setModalInfo}
                            rowEvents={rowEvents}
                            show={show}
                            handleClose={handleClose}
                            rejectApplication={rejectApplication}
                            approveApplication={approveApplication}
                            showSuccessAlert={showSuccessAlert}
                            showRejectAlert={showRejectAlert}
                            showModalBody={showModalBody}
                            showOkayButton={showOkayButton}
                            showModalButtons={showModalButtons}
                            updateSubmittedApplication={updateSubmittedApplication}
                            SetBusinessName={SetBusinessName}
                            businessName={businessName}
                            SetDescription={SetDescription}
                            description={description}
                            SetOwnerName={SetOwnerName}
                            ownerName={ownerName}
                            businessType={businessType}
                            SetBusinessType={SetBusinessType}
                            SetPhone={SetPhone}
                            phone={phone}
                            SetEmail={SetEmail}
                            email={email}
                            SetWebsite={SetWebsite}
                            website={website}
                            SetNaics={SetNaics}
                            naics={naics}
                            SetAddressLine1={SetAddressLine1}
                            addressLine1={addressLine1}
                            SetAddressLine2={SetAddressLine2}
                            addressLine2={addressLine2}
                            SetCity={SetCity}
                            city={city}
                            SetProvince={SetProvince}
                            province={province}
                            SetPostalcode={SetPostalcode}
                            postalCode={postalCode}
                            SetNumberOfEmployees={SetNumberOfEmployees}
                            numberOfEmployees={numberOfEmployees}
                            setIndigenousHeritage={setIndigenousHeritage}
                            indigenousHeritage={indigenousHeritage}
                            setIndigenousOwnership={setIndigenousOwnership}
                            indigenousOwnership={indigenousOwnership}
                            awsUrl={awsUrl}
                            setIsDirty={setIsDirty}
                            showUpdateSuccessAlert={showUpdateSuccessAlert}
                            showUpdateFailAlert={showUpdateFailAlert}
                        />
                    </Row>
                </Container>
            <Footer />
        </div>
    );
}

export { Submitted };
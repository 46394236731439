import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/auth/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            return { 
                ...prev, 
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                username: response.data.username, //setting refresh auth with username to show who is logged in
                userId: response.data.userId,
            }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
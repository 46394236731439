import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from "../hooks/useAuth";
import {Button, InputGroup, Image, Row, Col} from 'react-bootstrap';
import { BsFillLockFill } from "react-icons/bs";

const ResetFormLoggedIn = ({setShowPasswordModal, showSuccessAlert, setShowSuccessAlert, success, setSuccess }) => {

    const axiosPrivate = useAxiosPrivate();

    const { auth } = useAuth();
    const id = auth.userId;
    const userName = auth.username;

    const errRef = useRef();
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');

    const [errMsg, setErrMsg] = useState('');


    let capitalLettersRegex = /[A-Z]+/;
    let numberRegex = /[0-9]+/;
    let specialCharacterRegex = /[^A-Za-z0-9]+/;

    useEffect(() => {
        setErrMsg('');
    }, [currentPassword, confirmPassword, password])

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(!currentPassword) {
            setErrMsg("Please enter your current password");
        } else if(password !== confirmPassword){
            setErrMsg("Passwords do not match");
        } else if(!password) {
            setErrMsg("Please enter a new password")
        } else if(password.length < 8) {
            setErrMsg("Password cannot be less than 8 characters")
        } else if(!password.match(capitalLettersRegex)) {
            setErrMsg("Password must contain at least 1 capital letter")
        } else if(!password.match(numberRegex)) {
            setErrMsg("Password must contain at least 1 number")
        } else if(!password.match(specialCharacterRegex)) {
            setErrMsg("Password must contain at least 1 special character")
        }
        else {
          try {
            const response = await axiosPrivate.put(`/account/reset/${id}`, 
                {
                CurrentPassword: currentPassword,
                ConfirmPassword: confirmPassword, 
                PasswordHash: password,
                }
            )
            setSuccess(response.data);
            setShowSuccessAlert(true);
            setConfirmPassword('');
            setCurrentPassword('');
            setPassword('');
            } catch (err){
                if (!err?.response) {
                    setErrMsg('No Server Response');
                }
                else if(err){
                    setErrMsg(err.response.data.message)
                } else {
                    setErrMsg('Reset Failed');
                }
                errRef.current.focus();
            }   
        }
    };

    const AlertSuccess = () => {
        return(
            <>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <Image 
                            src="https://major-projects-directory.s3.ca-central-1.amazonaws.com/Images/Password+Reset.png"
                            style={{
                                width: 280
                            }}
                            >
                        </Image>
                    </Col>
                    <Col md={2}></Col>
                    <p>{success.message} for <strong>{userName} </strong></p>
                </Row>
                
            </>
        )
    };

    const ResetButton = () => {
        return(
            <Button variant="primary" type="submit">Reset</Button>
        )
    };

    const CloseButton = () => {
        return(
            <Button variant="success" onClick={() => setShowPasswordModal(false)}>Close window</Button>
        )
    };


    return (
        <section>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: 'red'}}>{errMsg}</p>
            <Form onSubmit={handleSubmit}>
            {success ? <AlertSuccess /> : null}
            <Form.Group className="mb-3" style={{ display: !success ? "block" : "none" }}>
                    <InputGroup>
                    <InputGroup.Text><BsFillLockFill /></InputGroup.Text>
                        <Form.Control
                        type="password"
                        id="current-password"
                        placeholder="Current password"
                        autoComplete="off"
                        onChange={(e) => {setCurrentPassword(e.target.value)}}
                        value={currentPassword}
                        required 
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" style={{ display: !success ? "block" : "none" }}>
                    <InputGroup>
                    <InputGroup.Text><BsFillLockFill /></InputGroup.Text>
                        <Form.Control
                        type="password"
                        id="new-password"
                        placeholder="New password"
                        autoComplete="off"
                        onChange={(e) => {setPassword(e.target.value)}}
                        value={password}
                        required 
                        />
                    </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" style={{ display: !success ? "block" : "none" }}>
                    <InputGroup>
                    <InputGroup.Text><BsFillLockFill /></InputGroup.Text>
                        <Form.Control 
                        type="password"
                        id="confirm-password"
                        placeholder="Confirm password"
                        autoComplete="off"
                        onChange={(e) => {setConfirmPassword(e.target.value)}}
                        value={confirmPassword}
                        required 
                        />
                    </InputGroup>
                </Form.Group>
                <div className="d-grid gap-2">
                {!success ? <ResetButton /> : null}
                {success ? <CloseButton /> : null}
                </div>
            </Form>
        </section>
    );
}

export { ResetFormLoggedIn };



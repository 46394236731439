import { React, useRef, useState } from "react";
import axios from '../api/axios';
import { ForgotForm } from '../Components/ForgotPasswordForm';
import '../App.css';

const ForgotPassword = () => {

    const forgotEmail = "/forgotpassword";
    const url = window.location.origin;

    const errRef = useRef();
    const successRef = useRef();
    const pwRef = useRef();
    const [email, setEmail] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const response = await axios.post('forgotpassword/finduser', 
            {
                Email: email
            })
            const emailResponse = await axios.post(forgotEmail, 
                {
                    Email: email,
                    Url: url,
                    Id: response.data.Id
                }
            )
            if(emailResponse.status >= 200 && emailResponse.status <= 203) {
                setSuccess(emailResponse.data);
                setShowSuccessAlert(true);
                setEmail('');
            } else {
                setErrMsg(emailResponse.data);
            }
        } catch (err){
            if (!err?.response) {
                setErrMsg('No Server Response');
            }
            else if(err){
                setErrMsg(err.response.data.message)
            } else {
                setErrMsg('Reset Failed');
            }
            errRef.current.focus();
        }   
    };

    return (
    <div className="loginBackground">
        <ForgotForm
            handleSubmit={handleSubmit}
            email={email}
            setEmail={setEmail}
            errRef={errRef}
            errMsg={errMsg}
            setErrMsg={setErrMsg}
            showSuccessAlert={showSuccessAlert}
            success={success}
        />
    </ div>
    );
}

export { ForgotPassword };



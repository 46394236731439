import React, {useState, useEffect, useRef} from 'react';
import { useSearchParams, useNavigate  } from 'react-router-dom';
import { Container, Row, Spinner } from 'react-bootstrap';
import { ApplicationForm } from '../Components/ApplicationForm';
import axios from '../api/axios';


const Application = () => {

    const [businessName, SetBusinessName] = useState('');
    const [description, SetDescription] = useState('');
    const [ownerName, SetOwnerName] = useState('');
    const [businessType, SetBusinessType] = useState('');
    const [phone, SetPhone] = useState('');
    const [email, SetEmail] = useState('');
    const [website, SetWebsite] = useState('');
    const [naics, SetNaics] = useState('');
    const [addressLine1, SetAddressLine1] = useState('');
    const [addressLine2, SetAddressLine2] = useState('');
    const [city, SetCity] = useState('');
    const [province, SetProvince] = useState('');
    const [postalCode, SetPostalcode] = useState('');
    const [numberOfEmployees, SetNumberOfEmployees] = useState('');
    const [indigenousOwnership, setIndigenousOwnership] = useState('');
    const [indigenousHeritage, SetIndigenousHeritage] = useState('');

    const [validated, setValidated] = useState(false);
    const [naicsError, setNaicsError] = useState('');
    const [success, setSuccess] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [s3Url, setS3Url] = useState(null);
    const uploadInput = useRef(null);

    const [searchParams] = useSearchParams();
    const inviteId = searchParams.get('id');
    const code = searchParams.get('code');
    const navigate = useNavigate();

    useEffect(() => {
        getInviteData()
    }, [])

    const getInviteData = async () => {
        try { 
            const response = await axios.get(`/invites/${inviteId}`, {})
            if(response.data.Code.toString() !== code || response.data.Id.toString() !== inviteId) {
                navigate("/invalidinvite")    //error page for when the code does not align with ID
            } else if(response.data.Status === 1) {
                navigate("/inviteresponded")
            } else {
                setIsLoading(false);
                return;
            }
        } catch (e) {
            console.log(e)
            if(e.response.status === 404) {
                navigate("/invalidinvite") //error for when ID does not exist
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            setNaicsError(!naics ? 'Please choose NAICS from the dropdown list' : '');
            setValidated(false);
            event.stopPropagation();
        } 
        setValidated(true);
        if (form.checkValidity() === true) {
            //s3
            let file = uploadInput.current.files[0];
            // Split the filename to get the name and type
            let fileParts = uploadInput.current.files[0].name.split('.');
            let fileName = fileParts[0];
            let fileType = fileParts[1];
            axios.post('/application/signS3', {
                fileName : fileName,
                fileType : fileType
            })
            .then(response => {
                const returnData = response.data.data.returnData;
                const signedRequest = returnData.signedRequest;
                const url = returnData.url;
                const options = {
                    headers: {
                      'Content-Type': fileType
                    }
                };
                axios.put(signedRequest, file, options)
                .then(result => {
                    axios.post('/application', {
                        BusinessName: businessName,
                        Description: description,
                        OwnerName: ownerName,
                        BusinessType: businessType,
                        Phone: phone,
                        Email: email,
                        Website: website,
                        NAICS: naics,
                        AddressLine1: addressLine1,
                        AddressLine2: addressLine2,
                        City: city,
                        Province: province,
                        PostalCode: postalCode,
                        NumberOfEmployees: numberOfEmployees,
                        AttachmentURL: url,
                        IndigenousOwnership: indigenousOwnership,
                        IndigenousHeritage: indigenousHeritage,
                        InviteId: inviteId,
                        Code: code,
                    }).then((res) => {
                        if(res.status === 200) {
                            form.reset();
                            setValidated(false);
                            setSuccess(res.data);
                            console.log(res.data);
                            setShowSuccess(true);
                        }
                    }).catch((error) => {
                        console.log(error.response.data); 
                        setShowSuccess(false);
                    }); 
                })
                .catch(error => {
                    alert("ERROR" + JSON.stringify(error));
                })
            })   
        }
    };

    return(
        <>
        {isLoading
                ? <>
                  <Container className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" variant="success" className='' style={{position: "fixed", top: "50%", left: "50%"}}/>
                  </Container>
                  </>
                : 
        
            <Container className="pt-2 pb-5" style={{backgroundColor: 'white'}}>
                <Row className="mb-5">
                    <ApplicationForm 
                        SetBusinessName={SetBusinessName}
                        SetDescription={SetDescription}
                        SetOwnerName={SetOwnerName}
                        SetBusinessType={SetBusinessType}
                        SetPhone={SetPhone}
                        SetEmail={SetEmail}
                        SetWebsite={SetWebsite}
                        SetNaics={SetNaics}
                        naics={naics}
                        SetAddressLine1={SetAddressLine1}
                        SetAddressLine2={SetAddressLine2}
                        SetCity={SetCity}
                        SetProvince={SetProvince}
                        SetPostalcode={SetPostalcode}
                        SetNumberOfEmployees={SetNumberOfEmployees}
                        setIndigenousOwnership={setIndigenousOwnership}
                        indigenousHeritage={indigenousHeritage}
                        SetIndigenousHeritage={SetIndigenousHeritage}
                        handleSubmit={handleSubmit}
                        validated={validated}
                        success={success}
                        showSuccess={showSuccess}
                        uploadInput={uploadInput}
                        setS3Url={setS3Url}
                        s3Url={s3Url}
                        setNaicsError={setNaicsError}
                        naicsError={naicsError}
                    />
                </Row>
            </Container>
        }
        </>
    )
}

export {Application}
import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar,NavDropdown, Modal, Button  } from "react-bootstrap";
import { Container } from "react-bootstrap";
import useLogout from '../hooks/useLogout';
import useAuth from "../hooks/useAuth";
import { BsPersonCircle, BsInfoCircle } from "react-icons/bs";
import { ResetFormLoggedIn } from '../Components/ResetPasswordFormLoggedIn';

const NavBar = () => {

  const { auth } = useAuth();

  const navigate = useNavigate();
  const logout = useLogout();

  const activeUser = auth.username;
  const role = auth.roles;

  const [viewAccessOnly, setViewAccessOnly] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [success, setSuccess] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const ModalContent = () => {
    return (
      <Modal
        size="lg"
        show={lgShow}
        scrollable={true}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            About the project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src="https://major-projects-directory.s3.ca-central-1.amazonaws.com/Files/SkyAlyne.pdf" 
          title="pdf" style={{width: '100%', height: 650, overflow: 'hidden'}} frameBorder="0"></iframe>
        </Modal.Body>
      </Modal>
    )
  }

  const PasswordModal = () => {
    return (
      <Modal
        size="md"
        show={showPasswordModal}
        scrollable={true}
        onHide={() => setShowPasswordModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="password-modal">
            Reset password
            <h6>{activeUser}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {!success ? 
        <p className="pb-2">Your password must be at least 8 characters and should include a combination of numbers, letters and special characters (!$@%).</p>
        : null}
          <ResetFormLoggedIn 
            setShowPasswordModal={setShowPasswordModal}
            success={success}
            setSuccess={setSuccess}
            showSuccessAlert={showSuccessAlert}
            setShowSuccessAlert={setShowSuccessAlert}
          />
        </Modal.Body>
      </Modal>
    )
  }

  useEffect(() => {
    if(role === 3) {
      setViewAccessOnly(true);
    } else {
      setViewAccessOnly(false)
    }
  },[])

  const signOut = async () => {
    await logout();
    navigate('/login');
  }

  const submittedButtonClicked = () => navigate('/submitted');
  const approvedButtonClicked = () => navigate('/approved');

  return (
    <Navbar variant="dark" className="p-0" collapseOnSelect expand="sm">
      <Container>
        <Navbar.Brand href="/" style={{ display: viewAccessOnly ? "none" : "block" }}>
          <img
            src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Logos/nacca-logo.png"
            alt="Nacca logo"
            width="110"
            height="80"
          />
        </Navbar.Brand>
        <Navbar.Brand href="/approved" style={{ display: viewAccessOnly ? "block" : "none" }}>
          <img
            src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Logos/nacca-logo.png"
            alt="Nacca logo"
            width="110"
            height="80"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto p-2">
            <Nav.Link href="/" style={{ display: viewAccessOnly ? "none" : "block" }}>Invite</Nav.Link>
            <Nav.Link onClick={submittedButtonClicked} style={{ display: viewAccessOnly ? "none" : "block" }}>Submitted</Nav.Link>
            <Nav.Link onClick={approvedButtonClicked}>Indigenous Businesses</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
        <Nav className="p-4">
          <Button variant="light" size="sm" onClick={() => setLgShow(true)}><BsInfoCircle /> Major Project Info</Button>
          <ModalContent />
        </Nav>
        <Nav><span><BsPersonCircle /></span></Nav>
        <Nav>
          <Navbar.Text>
            <NavDropdown title={activeUser} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => setShowPasswordModal(true)} id="navdropdown-text">Reset Password</NavDropdown.Item>
              <PasswordModal />
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={signOut} id="navdropdown-text">Logout <strong>{activeUser}</strong></NavDropdown.Item>
            </NavDropdown>
          </Navbar.Text>
        </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export {NavBar}

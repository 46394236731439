import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';


export const PasswordResetExpired = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-2);

    return (
        <Container className="pt-5 pb-5 " style={{backgroundColor: 'white'}}>
            <Row>
                <Col xs={1}></Col>
                <Col xs={10} style={{textAlign: 'center'}}>
                    <h1>Password reset link expired</h1>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col xs={12} style={{textAlign: 'center'}}>
                    <Image src='https://sky-alyne.s3.ca-central-1.amazonaws.com/404.png' className="pb-5 pt-5" 
                    style={{height: 400, alignContent: 'center'}}></Image>
                    <p>The password reset link has now expired</p>
                    <Button onClick={goBack}>Go Back</Button>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default PasswordResetExpired
import React, { useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import {Button, InputGroup, Container, Row, Col, Image, Card} from 'react-bootstrap';
import { BsFillEnvelopeFill } from "react-icons/bs";

const ForgotForm = ({handleSubmit, email, setEmail, errRef, errMsg, setErrMsg, success}) => {

    const navigate = useNavigate();

    useEffect(() => {
        setErrMsg('');
    }, [email])

    const returnToLogin = () => {
        navigate('/login')
    };

    const AlertSuccess = () => {
        return(
            <>
                <br/>
                <Image 
                    src="https://sky-alyne.s3.ca-central-1.amazonaws.com/mail+sent.png"
                    className="pb-2" 
                    style={{width: 160, alignContent: 'center'}}
                    >
                </Image>
                <p>{success.message}</p>
            </>
        )
    };

    const ResetButton = () => {
        return(
            <Button variant="primary" type="submit">Reset</Button>
        )
    };

    const ReturnHomeButton = () => {
        return(
            <Button variant="success" onClick={returnToLogin}>Return to login</Button>
        )
    };

    const BackToLoginButton = () => {
        return(
            <Button variant="link" className="text-center pt-3" style={{ textDecoration: 'none' }} onClick={returnToLogin}>← Back to login</Button>
            )
    };


    return (
    <div>
        <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col md={8} lg={6} xs={12}>
                <Card className="shadow">
                    <Card.Body>
                    <div className="mb-3 mt-md-4">
                        <div style={{textAlign: 'center'}}>
                            <Image 
                            src="https://sky-alyne.s3.ca-central-1.amazonaws.com/Logos/nacca-color.png"
                            className="pb-4 pt-2" 
                            style={{height: 120}}
                            >
                            </Image>
                            <h1 className="display-6 pb-3" id="left-text-align">Forgot your password?</h1>
                            <p id="left-text-align">If you are unable to sign in to your account we will send you instructions on how to reset your password at the email address below.</p>
                            {success ? <AlertSuccess /> : null}
                        </div>
                        <div className="mb-3">
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: 'red'}}>{errMsg}</p>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" style={{ display: !success ? "block" : "none" }}>
                                    <InputGroup>
                                    <InputGroup.Text><BsFillEnvelopeFill /></InputGroup.Text>
                                        <Form.Control
                                        type="email"
                                        placeholder="Email address"
                                        autoComplete="on"
                                        onChange={(e) => {setEmail(e.target.value)}}
                                        value={email}
                                        required 
                                        />
                                    </InputGroup>
                                </Form.Group>
                            <div className="d-grid gap-2">
                            {!success ? <ResetButton /> : null}
                            {success ? <ReturnHomeButton /> : null}
                            </div>
                            {!success ? <BackToLoginButton /> : null}
                        </Form>
                            <hr />
                            <p className="text-center">
                                <a href="https://fernsoftware.com/" target="blank" style={{ textDecoration: 'none' }}>Fern Software </a> 
                                ©{new Date().getFullYear()}
                            </p>
                            <p className="text-center">
                                <a href="https://fernsoftware.com/company/privacy" target="blank" style={{ textDecoration: 'none' }}>Privacy Policy </a>
                                -
                                <a href="https://fernsoftware.com/company/privacy" target="blank" style={{ textDecoration: 'none' }}> Terms of use</a>
                            </p>
                        </div>
                    </div>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </Container>
    </div>
    );
}

export { ForgotForm };


